//
// Copyright 2018 Google Inc.
//
// Permission is hereby granted, free of charge, to any person obtaining a copy
// of this software and associated documentation files (the "Software"), to deal
// in the Software without restriction, including without limitation the rights
// to use, copy, modify, merge, publish, distribute, sublicense, and/or sell
// copies of the Software, and to permit persons to whom the Software is
// furnished to do so, subject to the following conditions:
//
// The above copyright notice and this permission notice shall be included in
// all copies or substantial portions of the Software.
//
// THE SOFTWARE IS PROVIDED "AS IS", WITHOUT WARRANTY OF ANY KIND, EXPRESS OR
// IMPLIED, INCLUDING BUT NOT LIMITED TO THE WARRANTIES OF MERCHANTABILITY,
// FITNESS FOR A PARTICULAR PURPOSE AND NONINFRINGEMENT. IN NO EVENT SHALL THE
// AUTHORS OR COPYRIGHT HOLDERS BE LIABLE FOR ANY CLAIM, DAMAGES OR OTHER
// LIABILITY, WHETHER IN AN ACTION OF CONTRACT, TORT OR OTHERWISE, ARISING FROM,
// OUT OF OR IN CONNECTION WITH THE SOFTWARE OR THE USE OR OTHER DEALINGS IN
// THE SOFTWARE.
//

$scrim-color: on-surface !default;
$title-ink-color: on-surface !default;
$content-ink-color: on-surface !default;
$scroll-divider-color: on-surface !default;

$scrim-opacity: 0.32 !default;
$title-ink-opacity: 0.87 !default;
$content-ink-opacity: 0.6 !default;
$scroll-divider-opacity: 0.12 !default;

$min-width: 280px !default;
$max-width: 560px !default;
$margin: 16px !default;
$shape-radius: medium !default;
$title-bottom-padding: 9px !default;
$actions-padding: 8px !default;
$header-side-padding: 24px !default;

$close-icon-padding: 12px !default;
$title-side-padding: 16px !default;
$fullscreen-header-side-padding: 16px !default;

$sheet-close-icon-right: 12px !default;
$sheet-close-icon-top: 9px !default;

$z-index: 7 !default;
