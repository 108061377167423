.mdc-top-app-bar {
  background-color: #6200ee;
  background-color: var(--mdc-theme-primary, #6200ee);
  color: #fff;
  box-sizing: border-box;
  width: 100%;
  z-index: 4;
  flex-direction: column;
  justify-content: space-between;
  display: flex;
  position: fixed;
}

.mdc-top-app-bar .mdc-top-app-bar__action-item, .mdc-top-app-bar .mdc-top-app-bar__navigation-icon {
  color: #fff;
  color: var(--mdc-theme-on-primary, #fff);
}

.mdc-top-app-bar .mdc-top-app-bar__action-item:before, .mdc-top-app-bar .mdc-top-app-bar__action-item:after, .mdc-top-app-bar .mdc-top-app-bar__navigation-icon:before, .mdc-top-app-bar .mdc-top-app-bar__navigation-icon:after {
  background-color: #fff;
  background-color: var(--mdc-ripple-color, var(--mdc-theme-on-primary, #fff));
}

.mdc-top-app-bar .mdc-top-app-bar__action-item:hover:before, .mdc-top-app-bar .mdc-top-app-bar__action-item.mdc-ripple-surface--hover:before, .mdc-top-app-bar .mdc-top-app-bar__navigation-icon:hover:before, .mdc-top-app-bar .mdc-top-app-bar__navigation-icon.mdc-ripple-surface--hover:before {
  opacity: .08;
  opacity: var(--mdc-ripple-hover-opacity, .08);
}

.mdc-top-app-bar .mdc-top-app-bar__action-item.mdc-ripple-upgraded--background-focused:before, .mdc-top-app-bar .mdc-top-app-bar__action-item:not(.mdc-ripple-upgraded):focus:before, .mdc-top-app-bar .mdc-top-app-bar__navigation-icon.mdc-ripple-upgraded--background-focused:before, .mdc-top-app-bar .mdc-top-app-bar__navigation-icon:not(.mdc-ripple-upgraded):focus:before {
  opacity: .24;
  opacity: var(--mdc-ripple-focus-opacity, .24);
  transition-duration: 75ms;
}

.mdc-top-app-bar .mdc-top-app-bar__action-item:not(.mdc-ripple-upgraded):after, .mdc-top-app-bar .mdc-top-app-bar__navigation-icon:not(.mdc-ripple-upgraded):after {
  transition: opacity .15s linear;
}

.mdc-top-app-bar .mdc-top-app-bar__action-item:not(.mdc-ripple-upgraded):active:after, .mdc-top-app-bar .mdc-top-app-bar__navigation-icon:not(.mdc-ripple-upgraded):active:after {
  opacity: .24;
  opacity: var(--mdc-ripple-press-opacity, .24);
  transition-duration: 75ms;
}

.mdc-top-app-bar .mdc-top-app-bar__action-item.mdc-ripple-upgraded, .mdc-top-app-bar .mdc-top-app-bar__navigation-icon.mdc-ripple-upgraded {
  --mdc-ripple-fg-opacity: var(--mdc-ripple-press-opacity, .24);
}

.mdc-top-app-bar__row {
  box-sizing: border-box;
  width: 100%;
  height: 64px;
  display: flex;
  position: relative;
}

.mdc-top-app-bar__section {
  min-width: 0;
  z-index: 1;
  flex: auto;
  align-items: center;
  padding: 8px 12px;
  display: inline-flex;
}

.mdc-top-app-bar__section--align-start {
  order: -1;
  justify-content: flex-start;
}

.mdc-top-app-bar__section--align-end {
  order: 1;
  justify-content: flex-end;
}

.mdc-top-app-bar__title {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Roboto, sans-serif;
  font-family: var(--mdc-typography-headline6-font-family, var(--mdc-typography-font-family, Roboto, sans-serif));
  font-size: 1.25rem;
  font-size: var(--mdc-typography-headline6-font-size, 1.25rem);
  line-height: 2rem;
  line-height: var(--mdc-typography-headline6-line-height, 2rem);
  font-weight: 500;
  font-weight: var(--mdc-typography-headline6-font-weight, 500);
  letter-spacing: .0125em;
  letter-spacing: var(--mdc-typography-headline6-letter-spacing, .0125em);
  text-decoration: inherit;
  text-decoration: var(--mdc-typography-headline6-text-decoration, inherit);
  text-transform: inherit;
  text-transform: var(--mdc-typography-headline6-text-transform, inherit);
  text-overflow: ellipsis;
  white-space: nowrap;
  z-index: 1;
  padding-left: 20px;
  padding-right: 0;
  overflow: hidden;
}

[dir="rtl"] .mdc-top-app-bar__title, .mdc-top-app-bar__title[dir="rtl"] {
  padding-left: 0;
  padding-right: 20px;
}

.mdc-top-app-bar--short-collapsed {
  border-radius: 0 0 24px;
}

[dir="rtl"] .mdc-top-app-bar--short-collapsed, .mdc-top-app-bar--short-collapsed[dir="rtl"] {
  border-radius: 0 0 0 24px;
}

.mdc-top-app-bar--short {
  width: 100%;
  transition: width .25s cubic-bezier(.4, 0, .2, 1);
  top: 0;
  left: 0;
  right: auto;
}

[dir="rtl"] .mdc-top-app-bar--short, .mdc-top-app-bar--short[dir="rtl"] {
  left: auto;
  right: 0;
}

.mdc-top-app-bar--short .mdc-top-app-bar__row {
  height: 56px;
}

.mdc-top-app-bar--short .mdc-top-app-bar__section {
  padding: 4px;
}

.mdc-top-app-bar--short .mdc-top-app-bar__title {
  opacity: 1;
  transition: opacity .2s cubic-bezier(.4, 0, .2, 1);
}

.mdc-top-app-bar--short-collapsed {
  width: 56px;
  transition: width .3s cubic-bezier(.4, 0, .2, 1);
  box-shadow: 0 2px 4px -1px #0003, 0 4px 5px #00000024, 0 1px 10px #0000001f;
}

.mdc-top-app-bar--short-collapsed .mdc-top-app-bar__title {
  display: none;
}

.mdc-top-app-bar--short-collapsed .mdc-top-app-bar__action-item {
  transition: padding .15s cubic-bezier(.4, 0, .2, 1);
}

.mdc-top-app-bar--short-collapsed.mdc-top-app-bar--short-has-action-item {
  width: 112px;
}

.mdc-top-app-bar--short-collapsed.mdc-top-app-bar--short-has-action-item .mdc-top-app-bar__section--align-end {
  padding-left: 0;
  padding-right: 12px;
}

[dir="rtl"] .mdc-top-app-bar--short-collapsed.mdc-top-app-bar--short-has-action-item .mdc-top-app-bar__section--align-end, .mdc-top-app-bar--short-collapsed.mdc-top-app-bar--short-has-action-item .mdc-top-app-bar__section--align-end[dir="rtl"] {
  padding-left: 12px;
  padding-right: 0;
}

.mdc-top-app-bar--dense .mdc-top-app-bar__row {
  height: 48px;
}

.mdc-top-app-bar--dense .mdc-top-app-bar__section {
  padding: 0 4px;
}

.mdc-top-app-bar--dense .mdc-top-app-bar__title {
  padding-left: 12px;
  padding-right: 0;
}

[dir="rtl"] .mdc-top-app-bar--dense .mdc-top-app-bar__title, .mdc-top-app-bar--dense .mdc-top-app-bar__title[dir="rtl"] {
  padding-left: 0;
  padding-right: 12px;
}

.mdc-top-app-bar--prominent .mdc-top-app-bar__row {
  height: 128px;
}

.mdc-top-app-bar--prominent .mdc-top-app-bar__title {
  align-self: flex-end;
  padding-bottom: 2px;
}

.mdc-top-app-bar--prominent .mdc-top-app-bar__action-item, .mdc-top-app-bar--prominent .mdc-top-app-bar__navigation-icon {
  align-self: flex-start;
}

.mdc-top-app-bar--fixed {
  transition: box-shadow .2s linear;
}

.mdc-top-app-bar--fixed-scrolled {
  transition: box-shadow .2s linear;
  box-shadow: 0 2px 4px -1px #0003, 0 4px 5px #00000024, 0 1px 10px #0000001f;
}

.mdc-top-app-bar--dense.mdc-top-app-bar--prominent .mdc-top-app-bar__row {
  height: 96px;
}

.mdc-top-app-bar--dense.mdc-top-app-bar--prominent .mdc-top-app-bar__section {
  padding: 0 12px;
}

.mdc-top-app-bar--dense.mdc-top-app-bar--prominent .mdc-top-app-bar__title {
  padding-bottom: 9px;
  padding-left: 20px;
  padding-right: 0;
}

[dir="rtl"] .mdc-top-app-bar--dense.mdc-top-app-bar--prominent .mdc-top-app-bar__title, .mdc-top-app-bar--dense.mdc-top-app-bar--prominent .mdc-top-app-bar__title[dir="rtl"] {
  padding-left: 0;
  padding-right: 20px;
}

.mdc-top-app-bar--fixed-adjust {
  padding-top: 64px;
}

.mdc-top-app-bar--dense-fixed-adjust {
  padding-top: 48px;
}

.mdc-top-app-bar--short-fixed-adjust {
  padding-top: 56px;
}

.mdc-top-app-bar--prominent-fixed-adjust {
  padding-top: 128px;
}

.mdc-top-app-bar--dense-prominent-fixed-adjust {
  padding-top: 96px;
}

@media (max-width: 599px) {
  .mdc-top-app-bar__row {
    height: 56px;
  }

  .mdc-top-app-bar__section {
    padding: 4px;
  }

  .mdc-top-app-bar--short {
    transition: width .2s cubic-bezier(.4, 0, .2, 1);
  }

  .mdc-top-app-bar--short-collapsed {
    transition: width .25s cubic-bezier(.4, 0, .2, 1);
  }

  .mdc-top-app-bar--short-collapsed .mdc-top-app-bar__section--align-end {
    padding-left: 0;
    padding-right: 12px;
  }

  [dir="rtl"] .mdc-top-app-bar--short-collapsed .mdc-top-app-bar__section--align-end, .mdc-top-app-bar--short-collapsed .mdc-top-app-bar__section--align-end[dir="rtl"] {
    padding-left: 12px;
    padding-right: 0;
  }

  .mdc-top-app-bar--prominent .mdc-top-app-bar__title {
    padding-bottom: 6px;
  }

  .mdc-top-app-bar--fixed-adjust {
    padding-top: 56px;
  }
}

.mdc-dialog .mdc-dialog__surface {
  background-color: #fff;
  background-color: var(--mdc-theme-surface, #fff);
}

.mdc-dialog .mdc-dialog__scrim, .mdc-dialog .mdc-dialog__surface-scrim {
  background-color: #00000052;
}

.mdc-dialog .mdc-dialog__title {
  color: #000000de;
}

.mdc-dialog .mdc-dialog__content {
  color: #0009;
}

.mdc-dialog .mdc-dialog__close {
  color: #000;
  color: var(--mdc-theme-on-surface, #000);
}

.mdc-dialog .mdc-dialog__close .mdc-icon-button__ripple:before, .mdc-dialog .mdc-dialog__close .mdc-icon-button__ripple:after {
  background-color: #000;
  background-color: var(--mdc-ripple-color, var(--mdc-theme-on-surface, #000));
}

.mdc-dialog .mdc-dialog__close:hover .mdc-icon-button__ripple:before, .mdc-dialog .mdc-dialog__close.mdc-ripple-surface--hover .mdc-icon-button__ripple:before {
  opacity: .04;
  opacity: var(--mdc-ripple-hover-opacity, .04);
}

.mdc-dialog .mdc-dialog__close.mdc-ripple-upgraded--background-focused .mdc-icon-button__ripple:before, .mdc-dialog .mdc-dialog__close:not(.mdc-ripple-upgraded):focus .mdc-icon-button__ripple:before {
  opacity: .12;
  opacity: var(--mdc-ripple-focus-opacity, .12);
  transition-duration: 75ms;
}

.mdc-dialog .mdc-dialog__close:not(.mdc-ripple-upgraded) .mdc-icon-button__ripple:after {
  transition: opacity .15s linear;
}

.mdc-dialog .mdc-dialog__close:not(.mdc-ripple-upgraded):active .mdc-icon-button__ripple:after {
  opacity: .12;
  opacity: var(--mdc-ripple-press-opacity, .12);
  transition-duration: 75ms;
}

.mdc-dialog .mdc-dialog__close.mdc-ripple-upgraded {
  --mdc-ripple-fg-opacity: var(--mdc-ripple-press-opacity, .12);
}

.mdc-dialog.mdc-dialog--scrollable .mdc-dialog__title, .mdc-dialog.mdc-dialog--scrollable .mdc-dialog__actions, .mdc-dialog.mdc-dialog--scrollable.mdc-dialog-scroll-divider-footer .mdc-dialog__actions {
  border-color: #0000001f;
}

.mdc-dialog.mdc-dialog--scrollable .mdc-dialog__title {
  border-bottom: 1px solid #0000001f;
  margin-bottom: 0;
}

.mdc-dialog.mdc-dialog-scroll-divider-header.mdc-dialog--fullscreen .mdc-dialog__header {
  box-shadow: 0 3px 1px -2px #0003, 0 2px 2px #00000024, 0 1px 5px #0000001f;
}

.mdc-dialog .mdc-dialog__surface {
  border-radius: 4px;
  border-radius: var(--mdc-shape-medium, 4px);
}

.mdc-dialog__surface {
  box-shadow: 0 11px 15px -7px #0003, 0 24px 38px 3px #00000024, 0 9px 46px 8px #0000001f;
}

.mdc-dialog__title {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Roboto, sans-serif;
  font-family: var(--mdc-typography-headline6-font-family, var(--mdc-typography-font-family, Roboto, sans-serif));
  font-size: 1.25rem;
  font-size: var(--mdc-typography-headline6-font-size, 1.25rem);
  line-height: 2rem;
  line-height: var(--mdc-typography-headline6-line-height, 2rem);
  font-weight: 500;
  font-weight: var(--mdc-typography-headline6-font-weight, 500);
  letter-spacing: .0125em;
  letter-spacing: var(--mdc-typography-headline6-letter-spacing, .0125em);
  text-decoration: inherit;
  text-decoration: var(--mdc-typography-headline6-text-decoration, inherit);
  text-transform: inherit;
  text-transform: var(--mdc-typography-headline6-text-transform, inherit);
}

.mdc-dialog__content {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Roboto, sans-serif;
  font-family: var(--mdc-typography-body1-font-family, var(--mdc-typography-font-family, Roboto, sans-serif));
  font-size: 1rem;
  font-size: var(--mdc-typography-body1-font-size, 1rem);
  line-height: 1.5rem;
  line-height: var(--mdc-typography-body1-line-height, 1.5rem);
  font-weight: 400;
  font-weight: var(--mdc-typography-body1-font-weight, 400);
  letter-spacing: .03125em;
  letter-spacing: var(--mdc-typography-body1-letter-spacing, .03125em);
  text-decoration: inherit;
  text-decoration: var(--mdc-typography-body1-text-decoration, inherit);
  text-transform: inherit;
  text-transform: var(--mdc-typography-body1-text-transform, inherit);
}

.mdc-elevation-overlay {
  border-radius: inherit;
  pointer-events: none;
  opacity: 0;
  opacity: var(--mdc-elevation-overlay-opacity, 0);
  background-color: #fff;
  background-color: var(--mdc-elevation-overlay-color, #fff);
  transition: opacity .28s cubic-bezier(.4, 0, .2, 1);
  position: absolute;
}

.mdc-dialog, .mdc-dialog__scrim {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
}

.mdc-dialog {
  z-index: 7;
  z-index: var(--mdc-dialog-z-index, 7);
  display: none;
}

.mdc-dialog .mdc-dialog__content {
  padding: 20px 24px;
}

.mdc-dialog .mdc-dialog__surface {
  min-width: 280px;
}

@media (max-width: 592px) {
  .mdc-dialog .mdc-dialog__surface {
    max-width: calc(100vw - 32px);
  }
}

@media (min-width: 592px) {
  .mdc-dialog .mdc-dialog__surface {
    max-width: 560px;
  }
}

.mdc-dialog .mdc-dialog__surface {
  max-height: calc(100% - 32px);
}

.mdc-dialog.mdc-dialog--fullscreen .mdc-dialog__surface {
  max-width: none;
}

@media (max-width: 960px) {
  .mdc-dialog.mdc-dialog--fullscreen .mdc-dialog__surface {
    max-height: 560px;
    width: 560px;
  }

  .mdc-dialog.mdc-dialog--fullscreen .mdc-dialog__surface .mdc-dialog__close {
    right: -12px;
  }
}

@media (max-width: 720px) and (max-width: 672px) {
  .mdc-dialog.mdc-dialog--fullscreen .mdc-dialog__surface {
    width: calc(100vw - 112px);
  }
}

@media (max-width: 720px) and (min-width: 672px) {
  .mdc-dialog.mdc-dialog--fullscreen .mdc-dialog__surface {
    width: 560px;
  }
}

@media (max-width: 720px) and (max-height: 720px) {
  .mdc-dialog.mdc-dialog--fullscreen .mdc-dialog__surface {
    max-height: calc(100vh - 160px);
  }
}

@media (max-width: 720px) and (min-height: 720px) {
  .mdc-dialog.mdc-dialog--fullscreen .mdc-dialog__surface {
    max-height: 560px;
  }
}

@media (max-width: 720px) {
  .mdc-dialog.mdc-dialog--fullscreen .mdc-dialog__surface .mdc-dialog__close {
    right: -12px;
  }
}

@media (max-width: 720px) and (max-height: 400px) {
  .mdc-dialog.mdc-dialog--fullscreen .mdc-dialog__surface {
    height: 100%;
    max-height: 100vh;
    max-width: 100vw;
    width: 100vw;
    border-radius: 0;
  }

  .mdc-dialog.mdc-dialog--fullscreen .mdc-dialog__surface .mdc-dialog__close {
    order: -1;
    left: -12px;
  }

  .mdc-dialog.mdc-dialog--fullscreen .mdc-dialog__surface .mdc-dialog__header {
    justify-content: flex-start;
    padding: 0 16px 9px;
  }

  .mdc-dialog.mdc-dialog--fullscreen .mdc-dialog__surface .mdc-dialog__title {
    margin-left: -8px;
  }
}

@media (max-width: 600px) {
  .mdc-dialog.mdc-dialog--fullscreen .mdc-dialog__surface {
    height: 100%;
    max-height: 100vh;
    max-width: 100vw;
    width: 100vw;
    border-radius: 0;
  }

  .mdc-dialog.mdc-dialog--fullscreen .mdc-dialog__surface .mdc-dialog__close {
    order: -1;
    left: -12px;
  }

  .mdc-dialog.mdc-dialog--fullscreen .mdc-dialog__surface .mdc-dialog__header {
    justify-content: flex-start;
    padding: 0 16px 9px;
  }

  .mdc-dialog.mdc-dialog--fullscreen .mdc-dialog__surface .mdc-dialog__title {
    margin-left: -8px;
  }
}

@media (min-width: 960px) {
  .mdc-dialog.mdc-dialog--fullscreen .mdc-dialog__surface {
    width: calc(100vw - 400px);
  }

  .mdc-dialog.mdc-dialog--fullscreen .mdc-dialog__surface .mdc-dialog__close {
    right: -12px;
  }
}

.mdc-dialog.mdc-dialog__scrim--hidden .mdc-dialog__scrim {
  opacity: 0;
}

.mdc-dialog__scrim {
  opacity: 0;
  z-index: -1;
}

.mdc-dialog__container {
  box-sizing: border-box;
  height: 100%;
  opacity: 0;
  pointer-events: none;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  display: flex;
  transform: scale(.8);
}

.mdc-dialog__surface {
  box-sizing: border-box;
  max-width: 100%;
  max-height: 100%;
  pointer-events: auto;
  flex-direction: column;
  flex-grow: 0;
  flex-shrink: 0;
  display: flex;
  position: relative;
  overflow-y: auto;
}

.mdc-dialog__surface .mdc-elevation-overlay {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

[dir="rtl"] .mdc-dialog__surface, .mdc-dialog__surface[dir="rtl"] {
  text-align: right;
}

@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .mdc-dialog__surface {
    outline: 2px solid windowText;
  }
}

.mdc-dialog__surface:before {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  border-radius: inherit;
  content: "";
  pointer-events: none;
  border: 2px solid #0000;
  position: absolute;
  top: 0;
  left: 0;
}

@media screen and (forced-colors: active) {
  .mdc-dialog__surface:before {
    border-color: CanvasText;
  }
}

@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
  .mdc-dialog__surface:before {
    content: none;
  }
}

.mdc-dialog__title {
  box-sizing: border-box;
  flex-shrink: 0;
  margin: 0 0 1px;
  padding: 0 24px 9px;
  display: block;
  position: relative;
}

.mdc-dialog__title:before {
  width: 0;
  height: 40px;
  content: "";
  vertical-align: 0;
  display: inline-block;
}

[dir="rtl"] .mdc-dialog__title, .mdc-dialog__title[dir="rtl"] {
  text-align: right;
}

.mdc-dialog--scrollable .mdc-dialog__title {
  margin-bottom: 1px;
  padding-bottom: 15px;
}

.mdc-dialog--fullscreen .mdc-dialog__header {
  z-index: 1;
  border-bottom: 1px solid #0000;
  justify-content: space-between;
  align-items: baseline;
  padding: 0 24px 9px;
  display: inline-flex;
}

@media screen and (forced-colors: active) {
  .mdc-dialog--fullscreen .mdc-dialog__header {
    border-bottom-color: CanvasText;
  }
}

.mdc-dialog--fullscreen .mdc-dialog__header .mdc-dialog__close {
  right: -12px;
}

.mdc-dialog--fullscreen .mdc-dialog__title {
  border-bottom: 0;
  margin-bottom: 0;
  padding: 0;
}

.mdc-dialog--fullscreen.mdc-dialog--scrollable .mdc-dialog__title {
  border-bottom: 0;
  margin-bottom: 0;
}

.mdc-dialog--fullscreen .mdc-dialog__close {
  top: 5px;
}

.mdc-dialog--fullscreen.mdc-dialog--scrollable .mdc-dialog__actions {
  border-top: 1px solid #0000;
}

@media screen and (forced-colors: active) {
  .mdc-dialog--fullscreen.mdc-dialog--scrollable .mdc-dialog__actions {
    border-top-color: CanvasText;
  }
}

.mdc-dialog__content {
  box-sizing: border-box;
  flex-grow: 1;
  margin: 0;
  overflow: auto;
}

.mdc-dialog__content > :first-child {
  margin-top: 0;
}

.mdc-dialog__content > :last-child {
  margin-bottom: 0;
}

.mdc-dialog__title + .mdc-dialog__content, .mdc-dialog__header + .mdc-dialog__content {
  padding-top: 0;
}

.mdc-dialog--scrollable .mdc-dialog__title + .mdc-dialog__content {
  padding-top: 8px;
  padding-bottom: 8px;
}

.mdc-dialog__content .mdc-deprecated-list:first-child:last-child {
  padding: 6px 0 0;
}

.mdc-dialog--scrollable .mdc-dialog__content .mdc-deprecated-list:first-child:last-child {
  padding: 0;
}

.mdc-dialog__actions {
  box-sizing: border-box;
  min-height: 52px;
  border-top: 1px solid #0000;
  flex-wrap: wrap;
  flex-shrink: 0;
  justify-content: flex-end;
  align-items: center;
  margin: 0;
  padding: 8px;
  display: flex;
  position: relative;
}

@media screen and (forced-colors: active) {
  .mdc-dialog__actions {
    border-top-color: CanvasText;
  }
}

.mdc-dialog--stacked .mdc-dialog__actions {
  flex-direction: column;
  align-items: flex-end;
}

.mdc-dialog__button {
  max-width: 100%;
  text-align: right;
  margin-left: 8px;
  margin-right: 0;
}

[dir="rtl"] .mdc-dialog__button, .mdc-dialog__button[dir="rtl"] {
  margin-left: 0;
  margin-right: 8px;
}

.mdc-dialog__button:first-child, [dir="rtl"] .mdc-dialog__button:first-child, .mdc-dialog__button:first-child[dir="rtl"] {
  margin-left: 0;
  margin-right: 0;
}

[dir="rtl"] .mdc-dialog__button, .mdc-dialog__button[dir="rtl"] {
  text-align: left;
}

.mdc-dialog--stacked .mdc-dialog__button:not(:first-child) {
  margin-top: 12px;
}

.mdc-dialog--open, .mdc-dialog--opening, .mdc-dialog--closing {
  display: flex;
}

.mdc-dialog--opening .mdc-dialog__scrim {
  transition: opacity .15s linear;
}

.mdc-dialog--opening .mdc-dialog__container {
  transition: opacity 75ms linear, transform .15s cubic-bezier(0, 0, .2, 1);
}

.mdc-dialog--closing .mdc-dialog__scrim, .mdc-dialog--closing .mdc-dialog__container {
  transition: opacity 75ms linear;
}

.mdc-dialog--closing .mdc-dialog__container {
  transform: none;
}

.mdc-dialog--open .mdc-dialog__scrim {
  opacity: 1;
}

.mdc-dialog--open .mdc-dialog__container {
  opacity: 1;
  transform: none;
}

.mdc-dialog--open.mdc-dialog__surface-scrim--shown .mdc-dialog__surface-scrim {
  opacity: 1;
  z-index: 1;
}

.mdc-dialog--open.mdc-dialog__surface-scrim--hiding .mdc-dialog__surface-scrim {
  transition: opacity 75ms linear;
}

.mdc-dialog--open.mdc-dialog__surface-scrim--showing .mdc-dialog__surface-scrim {
  transition: opacity .15s linear;
}

.mdc-dialog__surface-scrim {
  opacity: 0;
  width: 100%;
  height: 100%;
  display: none;
  position: absolute;
}

.mdc-dialog__surface-scrim--shown .mdc-dialog__surface-scrim, .mdc-dialog__surface-scrim--showing .mdc-dialog__surface-scrim, .mdc-dialog__surface-scrim--hiding .mdc-dialog__surface-scrim {
  display: block;
}

.mdc-dialog-scroll-lock {
  overflow: hidden;
}

.mdc-dialog--no-content-padding .mdc-dialog__content {
  padding: 0;
}

.mdc-dialog--sheet .mdc-dialog__close {
  z-index: 1;
  position: absolute;
  top: 9px;
  right: 12px;
}

.mdc-touch-target-wrapper {
  display: inline;
}

.mdc-button {
  box-sizing: border-box;
  min-width: 64px;
  line-height: inherit;
  -webkit-user-select: none;
  user-select: none;
  -webkit-appearance: none;
  vertical-align: middle;
  background: none;
  border: none;
  outline: none;
  justify-content: center;
  align-items: center;
  display: inline-flex;
  position: relative;
  overflow: visible;
}

.mdc-button .mdc-elevation-overlay {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.mdc-button::-moz-focus-inner {
  border: 0;
  padding: 0;
}

.mdc-button:active {
  outline: none;
}

.mdc-button:hover {
  cursor: pointer;
}

.mdc-button:disabled {
  cursor: default;
  pointer-events: none;
}

.mdc-button .mdc-button__icon {
  vertical-align: top;
  margin-left: 0;
  margin-right: 8px;
  display: inline-block;
  position: relative;
}

[dir="rtl"] .mdc-button .mdc-button__icon, .mdc-button .mdc-button__icon[dir="rtl"] {
  margin-left: 8px;
  margin-right: 0;
}

.mdc-button .mdc-button__label {
  position: relative;
}

.mdc-button .mdc-button__focus-ring {
  display: none;
}

@media screen and (forced-colors: active) {
  .mdc-button.mdc-ripple-upgraded--background-focused .mdc-button__focus-ring, .mdc-button:not(.mdc-ripple-upgraded):focus .mdc-button__focus-ring {
    pointer-events: none;
    box-sizing: content-box;
    height: calc(100% + 4px);
    width: calc(100% + 4px);
    border: 2px solid #0000;
    border-radius: 6px;
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

@media screen and (forced-colors: active) and (forced-colors: active) {
  .mdc-button.mdc-ripple-upgraded--background-focused .mdc-button__focus-ring, .mdc-button:not(.mdc-ripple-upgraded):focus .mdc-button__focus-ring {
    border-color: CanvasText;
  }
}

@media screen and (forced-colors: active) {
  .mdc-button.mdc-ripple-upgraded--background-focused .mdc-button__focus-ring:after, .mdc-button:not(.mdc-ripple-upgraded):focus .mdc-button__focus-ring:after {
    content: "";
    height: calc(100% + 4px);
    width: calc(100% + 4px);
    border: 2px solid #0000;
    border-radius: 8px;
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

@media screen and (forced-colors: active) and (forced-colors: active) {
  .mdc-button.mdc-ripple-upgraded--background-focused .mdc-button__focus-ring:after, .mdc-button:not(.mdc-ripple-upgraded):focus .mdc-button__focus-ring:after {
    border-color: CanvasText;
  }
}

.mdc-button .mdc-button__touch {
  height: 48px;
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  transform: translateY(-50%);
}

.mdc-button__label + .mdc-button__icon {
  margin-left: 8px;
  margin-right: 0;
}

[dir="rtl"] .mdc-button__label + .mdc-button__icon, .mdc-button__label + .mdc-button__icon[dir="rtl"] {
  margin-left: 0;
  margin-right: 8px;
}

svg.mdc-button__icon {
  fill: currentColor;
}

.mdc-button--touch {
  margin-top: 6px;
  margin-bottom: 6px;
}

.mdc-button {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Roboto, sans-serif;
  font-family: var(--mdc-typography-button-font-family, var(--mdc-typography-font-family, Roboto, sans-serif));
  text-decoration: none;
  text-decoration: var(--mdc-typography-button-text-decoration, none);
  padding: 0 8px;
}

.mdc-button--unelevated {
  padding: 0 16px;
  transition: box-shadow .28s cubic-bezier(.4, 0, .2, 1);
}

.mdc-button--unelevated.mdc-button--icon-trailing {
  padding: 0 12px 0 16px;
}

.mdc-button--unelevated.mdc-button--icon-leading {
  padding: 0 16px 0 12px;
}

.mdc-button--raised {
  padding: 0 16px;
  transition: box-shadow .28s cubic-bezier(.4, 0, .2, 1);
}

.mdc-button--raised.mdc-button--icon-trailing {
  padding: 0 12px 0 16px;
}

.mdc-button--raised.mdc-button--icon-leading {
  padding: 0 16px 0 12px;
}

.mdc-button--outlined {
  border-style: solid;
  transition: border .28s cubic-bezier(.4, 0, .2, 1);
}

.mdc-button--outlined .mdc-button__ripple {
  border-style: solid;
  border-color: #0000;
}

@keyframes mdc-ripple-fg-radius-in {
  from {
    transform: translate(var(--mdc-ripple-fg-translate-start, 0)) scale(1);
    animation-timing-function: cubic-bezier(.4, 0, .2, 1);
  }

  to {
    transform: translate(var(--mdc-ripple-fg-translate-end, 0)) scale(var(--mdc-ripple-fg-scale, 1));
  }
}

@keyframes mdc-ripple-fg-opacity-in {
  from {
    opacity: 0;
    animation-timing-function: linear;
  }

  to {
    opacity: var(--mdc-ripple-fg-opacity, 0);
  }
}

@keyframes mdc-ripple-fg-opacity-out {
  from {
    opacity: var(--mdc-ripple-fg-opacity, 0);
    animation-timing-function: linear;
  }

  to {
    opacity: 0;
  }
}

.mdc-button {
  --mdc-ripple-fg-size: 0;
  --mdc-ripple-left: 0;
  --mdc-ripple-top: 0;
  --mdc-ripple-fg-scale: 1;
  --mdc-ripple-fg-translate-end: 0;
  --mdc-ripple-fg-translate-start: 0;
  -webkit-tap-highlight-color: #0000;
  will-change: transform, opacity;
}

.mdc-button .mdc-button__ripple:before, .mdc-button .mdc-button__ripple:after {
  opacity: 0;
  pointer-events: none;
  content: "";
  border-radius: 50%;
  position: absolute;
}

.mdc-button .mdc-button__ripple:before {
  z-index: 1;
  z-index: var(--mdc-ripple-z-index, 1);
  transition: opacity 15ms linear, background-color 15ms linear;
}

.mdc-button .mdc-button__ripple:after {
  z-index: 0;
  z-index: var(--mdc-ripple-z-index, 0);
}

.mdc-button.mdc-ripple-upgraded .mdc-button__ripple:before {
  transform: scale(var(--mdc-ripple-fg-scale, 1));
}

.mdc-button.mdc-ripple-upgraded .mdc-button__ripple:after {
  transform-origin: center;
  top: 0;
  left: 0;
  transform: scale(0);
}

.mdc-button.mdc-ripple-upgraded--unbounded .mdc-button__ripple:after {
  top: var(--mdc-ripple-top, 0);
  left: var(--mdc-ripple-left, 0);
}

.mdc-button.mdc-ripple-upgraded--foreground-activation .mdc-button__ripple:after {
  animation: .225s forwards mdc-ripple-fg-radius-in, 75ms forwards mdc-ripple-fg-opacity-in;
}

.mdc-button.mdc-ripple-upgraded--foreground-deactivation .mdc-button__ripple:after {
  transform: translate(var(--mdc-ripple-fg-translate-end, 0)) scale(var(--mdc-ripple-fg-scale, 1));
  animation: .15s mdc-ripple-fg-opacity-out;
}

.mdc-button .mdc-button__ripple:before, .mdc-button .mdc-button__ripple:after {
  width: 200%;
  height: 200%;
  top: -50%;
  left: -50%;
}

.mdc-button.mdc-ripple-upgraded .mdc-button__ripple:after {
  width: var(--mdc-ripple-fg-size, 100%);
  height: var(--mdc-ripple-fg-size, 100%);
}

.mdc-button__ripple {
  box-sizing: content-box;
  z-index: 0;
  position: absolute;
  inset: 0;
  overflow: hidden;
}

.mdc-button {
  font-family: Roboto, sans-serif;
  font-family: var(--mdc-text-button-label-text-font, var(--mdc-typography-button-font-family, var(--mdc-typography-font-family, Roboto, sans-serif)));
  font-size: .875rem;
  font-size: var(--mdc-text-button-label-text-size, var(--mdc-typography-button-font-size, .875rem));
  letter-spacing: .0892857em;
  letter-spacing: var(--mdc-text-button-label-text-tracking, var(--mdc-typography-button-letter-spacing, .0892857em));
  font-weight: 500;
  font-weight: var(--mdc-text-button-label-text-weight, var(--mdc-typography-button-font-weight, 500));
  text-transform: uppercase;
  text-transform: var(--mdc-text-button-label-text-transform, var(--mdc-typography-button-text-transform, uppercase));
  height: 36px;
  height: var(--mdc-text-button-container-height, 36px);
  border-radius: 4px;
  border-radius: var(--mdc-text-button-container-shape, var(--mdc-shape-small, 4px));
}

.mdc-button:not(:disabled) {
  color: #6200ee;
  color: var(--mdc-text-button-label-text-color, var(--mdc-theme-primary, #6200ee));
}

.mdc-button:disabled {
  color: #00000061;
  color: var(--mdc-text-button-disabled-label-text-color, #00000061);
}

.mdc-button .mdc-button__icon {
  font-size: 1.125rem;
  font-size: var(--mdc-text-button-with-icon-icon-size, 1.125rem);
  width: 1.125rem;
  width: var(--mdc-text-button-with-icon-icon-size, 1.125rem);
  height: 1.125rem;
  height: var(--mdc-text-button-with-icon-icon-size, 1.125rem);
}

.mdc-button .mdc-button__ripple:before, .mdc-button .mdc-button__ripple:after {
  background-color: #6200ee;
  background-color: var(--mdc-text-button-hover-state-layer-color, var(--mdc-theme-primary, #6200ee));
}

.mdc-button:hover .mdc-button__ripple:before, .mdc-button.mdc-ripple-surface--hover .mdc-button__ripple:before {
  opacity: .04;
  opacity: var(--mdc-text-button-hover-state-layer-opacity, .04);
}

.mdc-button.mdc-ripple-upgraded--background-focused .mdc-button__ripple:before, .mdc-button:not(.mdc-ripple-upgraded):focus .mdc-button__ripple:before {
  opacity: .12;
  opacity: var(--mdc-text-button-focus-state-layer-opacity, .12);
  transition-duration: 75ms;
}

.mdc-button:not(.mdc-ripple-upgraded) .mdc-button__ripple:after {
  transition: opacity .15s linear;
}

.mdc-button:not(.mdc-ripple-upgraded):active .mdc-button__ripple:after {
  opacity: .12;
  opacity: var(--mdc-text-button-pressed-state-layer-opacity, .12);
  transition-duration: 75ms;
}

.mdc-button.mdc-ripple-upgraded {
  --mdc-ripple-fg-opacity: var(--mdc-text-button-pressed-state-layer-opacity, .12);
}

.mdc-button .mdc-button__ripple {
  border-radius: 4px;
  border-radius: var(--mdc-text-button-container-shape, var(--mdc-shape-small, 4px));
}

.mdc-button--unelevated {
  font-family: Roboto, sans-serif;
  font-family: var(--mdc-filled-button-label-text-font, var(--mdc-typography-button-font-family, var(--mdc-typography-font-family, Roboto, sans-serif)));
  font-size: .875rem;
  font-size: var(--mdc-filled-button-label-text-size, var(--mdc-typography-button-font-size, .875rem));
  letter-spacing: .0892857em;
  letter-spacing: var(--mdc-filled-button-label-text-tracking, var(--mdc-typography-button-letter-spacing, .0892857em));
  font-weight: 500;
  font-weight: var(--mdc-filled-button-label-text-weight, var(--mdc-typography-button-font-weight, 500));
  text-transform: uppercase;
  text-transform: var(--mdc-filled-button-label-text-transform, var(--mdc-typography-button-text-transform, uppercase));
  height: 36px;
  height: var(--mdc-filled-button-container-height, 36px);
  border-radius: 4px;
  border-radius: var(--mdc-filled-button-container-shape, var(--mdc-shape-small, 4px));
}

.mdc-button--unelevated:not(:disabled) {
  background-color: #6200ee;
  background-color: var(--mdc-filled-button-container-color, var(--mdc-theme-primary, #6200ee));
}

.mdc-button--unelevated:disabled {
  background-color: #0000001f;
  background-color: var(--mdc-filled-button-disabled-container-color, #0000001f);
}

.mdc-button--unelevated:not(:disabled) {
  color: #fff;
  color: var(--mdc-filled-button-label-text-color, var(--mdc-theme-on-primary, #fff));
}

.mdc-button--unelevated:disabled {
  color: #00000061;
  color: var(--mdc-filled-button-disabled-label-text-color, #00000061);
}

.mdc-button--unelevated .mdc-button__icon {
  font-size: 1.125rem;
  font-size: var(--mdc-filled-button-with-icon-icon-size, 1.125rem);
  width: 1.125rem;
  width: var(--mdc-filled-button-with-icon-icon-size, 1.125rem);
  height: 1.125rem;
  height: var(--mdc-filled-button-with-icon-icon-size, 1.125rem);
}

.mdc-button--unelevated .mdc-button__ripple:before, .mdc-button--unelevated .mdc-button__ripple:after {
  background-color: #fff;
  background-color: var(--mdc-filled-button-hover-state-layer-color, var(--mdc-theme-on-primary, #fff));
}

.mdc-button--unelevated:hover .mdc-button__ripple:before, .mdc-button--unelevated.mdc-ripple-surface--hover .mdc-button__ripple:before {
  opacity: .08;
  opacity: var(--mdc-filled-button-hover-state-layer-opacity, .08);
}

.mdc-button--unelevated.mdc-ripple-upgraded--background-focused .mdc-button__ripple:before, .mdc-button--unelevated:not(.mdc-ripple-upgraded):focus .mdc-button__ripple:before {
  opacity: .24;
  opacity: var(--mdc-filled-button-focus-state-layer-opacity, .24);
  transition-duration: 75ms;
}

.mdc-button--unelevated:not(.mdc-ripple-upgraded) .mdc-button__ripple:after {
  transition: opacity .15s linear;
}

.mdc-button--unelevated:not(.mdc-ripple-upgraded):active .mdc-button__ripple:after {
  opacity: .24;
  opacity: var(--mdc-filled-button-pressed-state-layer-opacity, .24);
  transition-duration: 75ms;
}

.mdc-button--unelevated.mdc-ripple-upgraded {
  --mdc-ripple-fg-opacity: var(--mdc-filled-button-pressed-state-layer-opacity, .24);
}

.mdc-button--unelevated .mdc-button__ripple {
  border-radius: 4px;
  border-radius: var(--mdc-filled-button-container-shape, var(--mdc-shape-small, 4px));
}

.mdc-button--raised {
  font-family: Roboto, sans-serif;
  font-family: var(--mdc-protected-button-label-text-font, var(--mdc-typography-button-font-family, var(--mdc-typography-font-family, Roboto, sans-serif)));
  font-size: .875rem;
  font-size: var(--mdc-protected-button-label-text-size, var(--mdc-typography-button-font-size, .875rem));
  letter-spacing: .0892857em;
  letter-spacing: var(--mdc-protected-button-label-text-tracking, var(--mdc-typography-button-letter-spacing, .0892857em));
  font-weight: 500;
  font-weight: var(--mdc-protected-button-label-text-weight, var(--mdc-typography-button-font-weight, 500));
  text-transform: uppercase;
  text-transform: var(--mdc-protected-button-label-text-transform, var(--mdc-typography-button-text-transform, uppercase));
  height: 36px;
  height: var(--mdc-protected-button-container-height, 36px);
  border-radius: 4px;
  border-radius: var(--mdc-protected-button-container-shape, var(--mdc-shape-small, 4px));
  --mdc-elevation-box-shadow-for-gss: 0px 3px 1px -2px #0003, 0px 2px 2px 0px #00000024, 0px 1px 5px 0px #0000001f;
  box-shadow: 0 3px 1px -2px #0003, 0 2px 2px #00000024, 0 1px 5px #0000001f;
  box-shadow: var(--mdc-protected-button-container-elevation, var(--mdc-elevation-box-shadow-for-gss));
}

.mdc-button--raised:not(:disabled) {
  background-color: #6200ee;
  background-color: var(--mdc-protected-button-container-color, var(--mdc-theme-primary, #6200ee));
}

.mdc-button--raised:disabled {
  background-color: #0000001f;
  background-color: var(--mdc-protected-button-disabled-container-color, #0000001f);
}

.mdc-button--raised:not(:disabled) {
  color: #fff;
  color: var(--mdc-protected-button-label-text-color, var(--mdc-theme-on-primary, #fff));
}

.mdc-button--raised:disabled {
  color: #00000061;
  color: var(--mdc-protected-button-disabled-label-text-color, #00000061);
}

.mdc-button--raised .mdc-button__icon {
  font-size: 1.125rem;
  font-size: var(--mdc-protected-button-with-icon-icon-size, 1.125rem);
  width: 1.125rem;
  width: var(--mdc-protected-button-with-icon-icon-size, 1.125rem);
  height: 1.125rem;
  height: var(--mdc-protected-button-with-icon-icon-size, 1.125rem);
}

.mdc-button--raised .mdc-button__ripple:before, .mdc-button--raised .mdc-button__ripple:after {
  background-color: #fff;
  background-color: var(--mdc-protected-button-hover-state-layer-color, var(--mdc-theme-on-primary, #fff));
}

.mdc-button--raised:hover .mdc-button__ripple:before, .mdc-button--raised.mdc-ripple-surface--hover .mdc-button__ripple:before {
  opacity: .08;
  opacity: var(--mdc-protected-button-hover-state-layer-opacity, .08);
}

.mdc-button--raised.mdc-ripple-upgraded--background-focused .mdc-button__ripple:before, .mdc-button--raised:not(.mdc-ripple-upgraded):focus .mdc-button__ripple:before {
  opacity: .24;
  opacity: var(--mdc-protected-button-focus-state-layer-opacity, .24);
  transition-duration: 75ms;
}

.mdc-button--raised:not(.mdc-ripple-upgraded) .mdc-button__ripple:after {
  transition: opacity .15s linear;
}

.mdc-button--raised:not(.mdc-ripple-upgraded):active .mdc-button__ripple:after {
  opacity: .24;
  opacity: var(--mdc-protected-button-pressed-state-layer-opacity, .24);
  transition-duration: 75ms;
}

.mdc-button--raised.mdc-ripple-upgraded {
  --mdc-ripple-fg-opacity: var(--mdc-protected-button-pressed-state-layer-opacity, .24);
}

.mdc-button--raised .mdc-button__ripple {
  border-radius: 4px;
  border-radius: var(--mdc-protected-button-container-shape, var(--mdc-shape-small, 4px));
}

.mdc-button--raised.mdc-ripple-upgraded--background-focused, .mdc-button--raised:not(.mdc-ripple-upgraded):focus {
  --mdc-elevation-box-shadow-for-gss: 0px 2px 4px -1px #0003, 0px 4px 5px 0px #00000024, 0px 1px 10px 0px #0000001f;
  box-shadow: 0 2px 4px -1px #0003, 0 4px 5px #00000024, 0 1px 10px #0000001f;
  box-shadow: var(--mdc-protected-button-focus-container-elevation, var(--mdc-elevation-box-shadow-for-gss));
}

.mdc-button--raised:hover {
  --mdc-elevation-box-shadow-for-gss: 0px 2px 4px -1px #0003, 0px 4px 5px 0px #00000024, 0px 1px 10px 0px #0000001f;
  box-shadow: 0 2px 4px -1px #0003, 0 4px 5px #00000024, 0 1px 10px #0000001f;
  box-shadow: var(--mdc-protected-button-hover-container-elevation, var(--mdc-elevation-box-shadow-for-gss));
}

.mdc-button--raised:not(:disabled):active {
  --mdc-elevation-box-shadow-for-gss: 0px 5px 5px -3px #0003, 0px 8px 10px 1px #00000024, 0px 3px 14px 2px #0000001f;
  box-shadow: 0 5px 5px -3px #0003, 0 8px 10px 1px #00000024, 0 3px 14px 2px #0000001f;
  box-shadow: var(--mdc-protected-button-pressed-container-elevation, var(--mdc-elevation-box-shadow-for-gss));
}

.mdc-button--raised:disabled {
  --mdc-elevation-box-shadow-for-gss: 0px 0px 0px 0px #0003, 0px 0px 0px 0px #00000024, 0px 0px 0px 0px #0000001f;
  box-shadow: 0 0 #0003, 0 0 #00000024, 0 0 #0000001f;
  box-shadow: var(--mdc-protected-button-disabled-container-elevation, var(--mdc-elevation-box-shadow-for-gss));
}

.mdc-button--outlined {
  font-family: Roboto, sans-serif;
  font-family: var(--mdc-outlined-button-label-text-font, var(--mdc-typography-button-font-family, var(--mdc-typography-font-family, Roboto, sans-serif)));
  font-size: .875rem;
  font-size: var(--mdc-outlined-button-label-text-size, var(--mdc-typography-button-font-size, .875rem));
  letter-spacing: .0892857em;
  letter-spacing: var(--mdc-outlined-button-label-text-tracking, var(--mdc-typography-button-letter-spacing, .0892857em));
  font-weight: 500;
  font-weight: var(--mdc-outlined-button-label-text-weight, var(--mdc-typography-button-font-weight, 500));
  text-transform: uppercase;
  text-transform: var(--mdc-outlined-button-label-text-transform, var(--mdc-typography-button-text-transform, uppercase));
  height: 36px;
  height: var(--mdc-outlined-button-container-height, 36px);
  border-radius: 4px;
  border-radius: var(--mdc-outlined-button-container-shape, var(--mdc-shape-small, 4px));
  border-width: 1px;
  border-width: var(--mdc-outlined-button-outline-width, 1px);
  padding: 0 15px;
}

.mdc-button--outlined:not(:disabled) {
  color: #6200ee;
  color: var(--mdc-outlined-button-label-text-color, var(--mdc-theme-primary, #6200ee));
}

.mdc-button--outlined:disabled {
  color: #00000061;
  color: var(--mdc-outlined-button-disabled-label-text-color, #00000061);
}

.mdc-button--outlined .mdc-button__icon {
  font-size: 1.125rem;
  font-size: var(--mdc-outlined-button-with-icon-icon-size, 1.125rem);
  width: 1.125rem;
  width: var(--mdc-outlined-button-with-icon-icon-size, 1.125rem);
  height: 1.125rem;
  height: var(--mdc-outlined-button-with-icon-icon-size, 1.125rem);
}

.mdc-button--outlined .mdc-button__ripple:before, .mdc-button--outlined .mdc-button__ripple:after {
  background-color: #6200ee;
  background-color: var(--mdc-outlined-button-hover-state-layer-color, var(--mdc-theme-primary, #6200ee));
}

.mdc-button--outlined:hover .mdc-button__ripple:before, .mdc-button--outlined.mdc-ripple-surface--hover .mdc-button__ripple:before {
  opacity: .04;
  opacity: var(--mdc-outlined-button-hover-state-layer-opacity, .04);
}

.mdc-button--outlined.mdc-ripple-upgraded--background-focused .mdc-button__ripple:before, .mdc-button--outlined:not(.mdc-ripple-upgraded):focus .mdc-button__ripple:before {
  opacity: .12;
  opacity: var(--mdc-outlined-button-focus-state-layer-opacity, .12);
  transition-duration: 75ms;
}

.mdc-button--outlined:not(.mdc-ripple-upgraded) .mdc-button__ripple:after {
  transition: opacity .15s linear;
}

.mdc-button--outlined:not(.mdc-ripple-upgraded):active .mdc-button__ripple:after {
  opacity: .12;
  opacity: var(--mdc-outlined-button-pressed-state-layer-opacity, .12);
  transition-duration: 75ms;
}

.mdc-button--outlined.mdc-ripple-upgraded {
  --mdc-ripple-fg-opacity: var(--mdc-outlined-button-pressed-state-layer-opacity, .12);
}

.mdc-button--outlined .mdc-button__ripple {
  border-radius: 4px;
  border-radius: var(--mdc-outlined-button-container-shape, var(--mdc-shape-small, 4px));
}

.mdc-button--outlined:not(:disabled) {
  border-color: #0000001f;
  border-color: var(--mdc-outlined-button-outline-color, #0000001f);
}

.mdc-button--outlined:disabled {
  border-color: #0000001f;
  border-color: var(--mdc-outlined-button-disabled-outline-color, #0000001f);
}

.mdc-button--outlined.mdc-button--icon-trailing {
  padding: 0 11px 0 15px;
}

.mdc-button--outlined.mdc-button--icon-leading {
  padding: 0 15px 0 11px;
}

.mdc-button--outlined .mdc-button__ripple {
  border-width: 1px;
  border-width: var(--mdc-outlined-button-outline-width, 1px);
  inset: -1px;
}

.mdc-button--outlined .mdc-button__touch {
  left: -1px;
  left: calc(-1 * var(--mdc-outlined-button-outline-width, 1px));
  width: calc(100% + 2px);
  width: calc(100% + 2 * var(--mdc-outlined-button-outline-width, 1px));
}

.mdc-button--raised .mdc-button__icon, .mdc-button--unelevated .mdc-button__icon, .mdc-button--outlined .mdc-button__icon {
  margin-left: -4px;
  margin-right: 8px;
}

[dir="rtl"] .mdc-button--raised .mdc-button__icon, [dir="rtl"] .mdc-button--unelevated .mdc-button__icon, [dir="rtl"] .mdc-button--outlined .mdc-button__icon, .mdc-button--raised .mdc-button__icon[dir="rtl"], .mdc-button--unelevated .mdc-button__icon[dir="rtl"], .mdc-button--outlined .mdc-button__icon[dir="rtl"], .mdc-button--raised .mdc-button__label + .mdc-button__icon, .mdc-button--unelevated .mdc-button__label + .mdc-button__icon, .mdc-button--outlined .mdc-button__label + .mdc-button__icon {
  margin-left: 8px;
  margin-right: -4px;
}

[dir="rtl"] .mdc-button--raised .mdc-button__label + .mdc-button__icon, [dir="rtl"] .mdc-button--unelevated .mdc-button__label + .mdc-button__icon, [dir="rtl"] .mdc-button--outlined .mdc-button__label + .mdc-button__icon, .mdc-button--raised .mdc-button__label + .mdc-button__icon[dir="rtl"], .mdc-button--unelevated .mdc-button__label + .mdc-button__icon[dir="rtl"], .mdc-button--outlined .mdc-button__label + .mdc-button__icon[dir="rtl"] {
  margin-left: -4px;
  margin-right: 8px;
}

.mdc-icon-button {
  width: 48px;
  height: 48px;
  padding: 12px;
  font-size: 24px;
}

.mdc-icon-button .mdc-icon-button__focus-ring {
  display: none;
}

.mdc-icon-button.mdc-ripple-upgraded--background-focused .mdc-icon-button__focus-ring, .mdc-icon-button:not(.mdc-ripple-upgraded):focus .mdc-icon-button__focus-ring {
  max-height: 48px;
  max-width: 48px;
  display: block;
}

@media screen and (forced-colors: active) {
  .mdc-icon-button.mdc-ripple-upgraded--background-focused .mdc-icon-button__focus-ring, .mdc-icon-button:not(.mdc-ripple-upgraded):focus .mdc-icon-button__focus-ring {
    pointer-events: none;
    box-sizing: content-box;
    height: 100%;
    width: 100%;
    border: 2px solid #0000;
    border-radius: 6px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

@media screen and (forced-colors: active) and (forced-colors: active) {
  .mdc-icon-button.mdc-ripple-upgraded--background-focused .mdc-icon-button__focus-ring, .mdc-icon-button:not(.mdc-ripple-upgraded):focus .mdc-icon-button__focus-ring {
    border-color: CanvasText;
  }
}

@media screen and (forced-colors: active) {
  .mdc-icon-button.mdc-ripple-upgraded--background-focused .mdc-icon-button__focus-ring:after, .mdc-icon-button:not(.mdc-ripple-upgraded):focus .mdc-icon-button__focus-ring:after {
    content: "";
    height: calc(100% + 4px);
    width: calc(100% + 4px);
    border: 2px solid #0000;
    border-radius: 8px;
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

@media screen and (forced-colors: active) and (forced-colors: active) {
  .mdc-icon-button.mdc-ripple-upgraded--background-focused .mdc-icon-button__focus-ring:after, .mdc-icon-button:not(.mdc-ripple-upgraded):focus .mdc-icon-button__focus-ring:after {
    border-color: CanvasText;
  }
}

.mdc-icon-button.mdc-icon-button--reduced-size .mdc-icon-button__ripple {
  width: 40px;
  height: 40px;
  margin: 4px;
}

.mdc-icon-button.mdc-icon-button--reduced-size.mdc-ripple-upgraded--background-focused .mdc-icon-button__focus-ring, .mdc-icon-button.mdc-icon-button--reduced-size:not(.mdc-ripple-upgraded):focus .mdc-icon-button__focus-ring {
  max-height: 40px;
  max-width: 40px;
}

.mdc-icon-button .mdc-icon-button__touch {
  height: 48px;
  width: 48px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.mdc-icon-button:disabled {
  color: #00000061;
  color: var(--mdc-theme-text-disabled-on-light, #00000061);
}

.mdc-icon-button svg, .mdc-icon-button img {
  width: 24px;
  height: 24px;
}

.mdc-icon-button {
  box-sizing: border-box;
  fill: currentColor;
  color: inherit;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  z-index: 0;
  background-color: #0000;
  border: none;
  outline: none;
  text-decoration: none;
  display: inline-block;
  position: relative;
  overflow: visible;
}

.mdc-icon-button .mdc-icon-button__touch {
  height: 48px;
  width: 48px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.mdc-icon-button:disabled {
  cursor: default;
  pointer-events: none;
}

.mdc-icon-button--display-flex {
  justify-content: center;
  align-items: center;
  display: inline-flex;
}

.mdc-icon-button__icon {
  display: inline-block;
}

.mdc-icon-button__icon.mdc-icon-button__icon--on, .mdc-icon-button--on .mdc-icon-button__icon {
  display: none;
}

.mdc-icon-button--on .mdc-icon-button__icon.mdc-icon-button__icon--on {
  display: inline-block;
}

.mdc-icon-button__link {
  height: 100%;
  width: 100%;
  outline: none;
  position: absolute;
  top: 0;
  left: 0;
}

.mdc-icon-button {
  --mdc-ripple-fg-size: 0;
  --mdc-ripple-left: 0;
  --mdc-ripple-top: 0;
  --mdc-ripple-fg-scale: 1;
  --mdc-ripple-fg-translate-end: 0;
  --mdc-ripple-fg-translate-start: 0;
  -webkit-tap-highlight-color: #0000;
  will-change: transform, opacity;
}

.mdc-icon-button .mdc-icon-button__ripple:before, .mdc-icon-button .mdc-icon-button__ripple:after {
  opacity: 0;
  pointer-events: none;
  content: "";
  border-radius: 50%;
  position: absolute;
}

.mdc-icon-button .mdc-icon-button__ripple:before {
  z-index: 1;
  z-index: var(--mdc-ripple-z-index, 1);
  transition: opacity 15ms linear, background-color 15ms linear;
}

.mdc-icon-button .mdc-icon-button__ripple:after {
  z-index: 0;
  z-index: var(--mdc-ripple-z-index, 0);
}

.mdc-icon-button.mdc-ripple-upgraded .mdc-icon-button__ripple:before {
  transform: scale(var(--mdc-ripple-fg-scale, 1));
}

.mdc-icon-button.mdc-ripple-upgraded .mdc-icon-button__ripple:after {
  transform-origin: center;
  top: 0;
  left: 0;
  transform: scale(0);
}

.mdc-icon-button.mdc-ripple-upgraded--unbounded .mdc-icon-button__ripple:after {
  top: var(--mdc-ripple-top, 0);
  left: var(--mdc-ripple-left, 0);
}

.mdc-icon-button.mdc-ripple-upgraded--foreground-activation .mdc-icon-button__ripple:after {
  animation: .225s forwards mdc-ripple-fg-radius-in, 75ms forwards mdc-ripple-fg-opacity-in;
}

.mdc-icon-button.mdc-ripple-upgraded--foreground-deactivation .mdc-icon-button__ripple:after {
  transform: translate(var(--mdc-ripple-fg-translate-end, 0)) scale(var(--mdc-ripple-fg-scale, 1));
  animation: .15s mdc-ripple-fg-opacity-out;
}

.mdc-icon-button .mdc-icon-button__ripple:before, .mdc-icon-button .mdc-icon-button__ripple:after {
  width: 100%;
  height: 100%;
  top: 0%;
  left: 0%;
}

.mdc-icon-button.mdc-ripple-upgraded .mdc-icon-button__ripple:before, .mdc-icon-button.mdc-ripple-upgraded .mdc-icon-button__ripple:after {
  top: var(--mdc-ripple-top, calc(50% - 50%));
  left: var(--mdc-ripple-left, calc(50% - 50%));
  width: var(--mdc-ripple-fg-size, 100%);
  height: var(--mdc-ripple-fg-size, 100%);
}

.mdc-icon-button.mdc-ripple-upgraded .mdc-icon-button__ripple:after {
  width: var(--mdc-ripple-fg-size, 100%);
  height: var(--mdc-ripple-fg-size, 100%);
}

.mdc-icon-button .mdc-icon-button__ripple:before, .mdc-icon-button .mdc-icon-button__ripple:after {
  background-color: #000;
  background-color: var(--mdc-ripple-color, #000);
}

.mdc-icon-button:hover .mdc-icon-button__ripple:before, .mdc-icon-button.mdc-ripple-surface--hover .mdc-icon-button__ripple:before {
  opacity: .04;
  opacity: var(--mdc-ripple-hover-opacity, .04);
}

.mdc-icon-button.mdc-ripple-upgraded--background-focused .mdc-icon-button__ripple:before, .mdc-icon-button:not(.mdc-ripple-upgraded):focus .mdc-icon-button__ripple:before {
  opacity: .12;
  opacity: var(--mdc-ripple-focus-opacity, .12);
  transition-duration: 75ms;
}

.mdc-icon-button:not(.mdc-ripple-upgraded) .mdc-icon-button__ripple:after {
  transition: opacity .15s linear;
}

.mdc-icon-button:not(.mdc-ripple-upgraded):active .mdc-icon-button__ripple:after {
  opacity: .12;
  opacity: var(--mdc-ripple-press-opacity, .12);
  transition-duration: 75ms;
}

.mdc-icon-button.mdc-ripple-upgraded {
  --mdc-ripple-fg-opacity: var(--mdc-ripple-press-opacity, .12);
}

.mdc-icon-button .mdc-icon-button__ripple {
  height: 100%;
  pointer-events: none;
  width: 100%;
  z-index: -1;
  position: absolute;
  top: 0;
  left: 0;
}

.mdc-tooltip__surface, .mdc-tooltip__caret-surface-top, .mdc-tooltip__caret-surface-bottom {
  border-radius: 4px;
  border-radius: var(--mdc-shape-small, 4px);
}

.mdc-tooltip__surface {
  color: #fff;
  color: var(--mdc-theme-text-primary-on-dark, white);
  word-break: break-all;
  word-break: var(--mdc-tooltip-word-break, normal);
  overflow-wrap: anywhere;
  background-color: #0009;
}

.mdc-tooltip {
  z-index: 9;
}

.mdc-tooltip--showing-transition .mdc-tooltip__surface-animation {
  transition: opacity .15s cubic-bezier(0, 0, .2, 1), transform .15s cubic-bezier(0, 0, .2, 1);
}

.mdc-tooltip--hide-transition .mdc-tooltip__surface-animation {
  transition: opacity 75ms cubic-bezier(.4, 0, 1, 1);
}

.mdc-tooltip__title {
  color: #000000de;
  color: var(--mdc-theme-text-primary-on-light, #000000de);
}

.mdc-tooltip__content {
  color: #0009;
}

.mdc-tooltip__content-link {
  color: #6200ee;
  color: var(--mdc-theme-primary, #6200ee);
}

.mdc-tooltip {
  display: none;
  position: fixed;
}

.mdc-tooltip.mdc-tooltip--rich .mdc-tooltip__surface, .mdc-tooltip.mdc-tooltip--rich .mdc-tooltip__caret-surface-top, .mdc-tooltip.mdc-tooltip--rich .mdc-tooltip__caret-surface-bottom {
  background-color: #fff;
}

.mdc-tooltip-wrapper--rich {
  position: relative;
}

.mdc-tooltip--shown, .mdc-tooltip--showing, .mdc-tooltip--hide {
  display: inline-flex;
}

.mdc-tooltip--shown.mdc-tooltip--rich, .mdc-tooltip--showing.mdc-tooltip--rich, .mdc-tooltip--hide.mdc-tooltip--rich {
  display: inline-block;
  position: absolute;
  left: -320px;
}

.mdc-tooltip__surface {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Roboto, sans-serif;
  font-family: var(--mdc-typography-caption-font-family, var(--mdc-typography-font-family, Roboto, sans-serif));
  font-size: .75rem;
  font-size: var(--mdc-typography-caption-font-size, .75rem);
  font-weight: 400;
  font-weight: var(--mdc-typography-caption-font-weight, 400);
  letter-spacing: .0333333em;
  letter-spacing: var(--mdc-typography-caption-letter-spacing, .0333333em);
  text-decoration: inherit;
  text-decoration: var(--mdc-typography-caption-text-decoration, inherit);
  text-transform: inherit;
  text-transform: var(--mdc-typography-caption-text-transform, inherit);
  min-width: 40px;
  max-width: 200px;
  min-height: 24px;
  max-height: 40vh;
  box-sizing: border-box;
  text-align: center;
  padding: 4px 8px;
  line-height: 16px;
  overflow: hidden;
}

.mdc-tooltip__surface:before {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  border-radius: inherit;
  content: "";
  pointer-events: none;
  border: 1px solid #0000;
  position: absolute;
  top: 0;
  left: 0;
}

@media screen and (forced-colors: active) {
  .mdc-tooltip__surface:before {
    border-color: CanvasText;
  }
}

.mdc-tooltip--rich .mdc-tooltip__surface {
  min-height: 24px;
  min-width: 40px;
  max-width: 320px;
  border-radius: 4px;
  flex-direction: column;
  align-items: flex-start;
  line-height: 20px;
  display: flex;
  position: relative;
  box-shadow: 0 3px 1px -2px #0003, 0 2px 2px #00000024, 0 1px 5px #0000001f;
}

.mdc-tooltip--rich .mdc-tooltip__surface .mdc-elevation-overlay {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.mdc-tooltip--multiline .mdc-tooltip__surface {
  text-align: left;
}

[dir="rtl"] .mdc-tooltip--multiline .mdc-tooltip__surface, .mdc-tooltip--multiline .mdc-tooltip__surface[dir="rtl"] {
  text-align: right;
}

.mdc-tooltip__surface .mdc-tooltip__title {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Roboto, sans-serif;
  line-height: 20px;
  font-family: var(--mdc-typography-subtitle2-font-family, var(--mdc-typography-font-family, Roboto, sans-serif));
  font-size: .875rem;
  font-size: var(--mdc-typography-subtitle2-font-size, .875rem);
  line-height: 1.375rem;
  line-height: var(--mdc-typography-subtitle2-line-height, 1.375rem);
  font-weight: 500;
  font-weight: var(--mdc-typography-subtitle2-font-weight, 500);
  letter-spacing: .00714286em;
  letter-spacing: var(--mdc-typography-subtitle2-letter-spacing, .00714286em);
  text-decoration: inherit;
  text-decoration: var(--mdc-typography-subtitle2-text-decoration, inherit);
  text-transform: inherit;
  text-transform: var(--mdc-typography-subtitle2-text-transform, inherit);
  margin: 0 8px;
  display: block;
}

.mdc-tooltip__surface .mdc-tooltip__title:before {
  width: 0;
  height: 24px;
  content: "";
  vertical-align: 0;
  display: inline-block;
}

.mdc-tooltip__surface .mdc-tooltip__content {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Roboto, sans-serif;
  font-family: var(--mdc-typography-body2-font-family, var(--mdc-typography-font-family, Roboto, sans-serif));
  font-size: .875rem;
  font-size: var(--mdc-typography-body2-font-size, .875rem);
  line-height: 1.25rem;
  line-height: var(--mdc-typography-body2-line-height, 1.25rem);
  font-weight: 400;
  font-weight: var(--mdc-typography-body2-font-weight, 400);
  letter-spacing: .0178571em;
  letter-spacing: var(--mdc-typography-body2-letter-spacing, .0178571em);
  text-decoration: inherit;
  text-decoration: var(--mdc-typography-body2-text-decoration, inherit);
  text-transform: inherit;
  text-transform: var(--mdc-typography-body2-text-transform, inherit);
  max-width: 184px;
  text-align: left;
  margin: 8px;
}

[dir="rtl"] .mdc-tooltip__surface .mdc-tooltip__content, .mdc-tooltip__surface .mdc-tooltip__content[dir="rtl"] {
  text-align: right;
}

.mdc-tooltip--rich .mdc-tooltip__surface .mdc-tooltip__content {
  max-width: 304px;
  align-self: stretch;
}

.mdc-tooltip__surface .mdc-tooltip__content-link {
  text-decoration: none;
}

.mdc-tooltip--rich-actions, .mdc-tooltip__content, .mdc-tooltip__title {
  z-index: 1;
}

.mdc-tooltip__surface-animation {
  opacity: 0;
  will-change: transform, opacity;
  transform: scale(.8);
}

.mdc-tooltip--shown .mdc-tooltip__surface-animation {
  opacity: 1;
  transform: scale(1);
}

.mdc-tooltip--hide .mdc-tooltip__surface-animation {
  transform: scale(1);
}

.mdc-tooltip__caret-surface-top, .mdc-tooltip__caret-surface-bottom {
  height: 24px;
  width: 24px;
  position: absolute;
  transform: rotate(35deg)skewY(20deg)scaleX(.939693);
}

.mdc-tooltip__caret-surface-top .mdc-elevation-overlay, .mdc-tooltip__caret-surface-bottom .mdc-elevation-overlay {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.mdc-tooltip__caret-surface-bottom {
  z-index: -1;
  outline: 1px solid #0000;
  box-shadow: 0 3px 1px -2px #0003, 0 2px 2px #00000024, 0 1px 5px #0000001f;
}

@media screen and (forced-colors: active) {
  .mdc-tooltip__caret-surface-bottom {
    outline-color: CanvasText;
  }
}

.mdc-data-table__content {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Roboto, sans-serif;
  font-family: var(--mdc-typography-body2-font-family, var(--mdc-typography-font-family, Roboto, sans-serif));
  font-size: .875rem;
  font-size: var(--mdc-typography-body2-font-size, .875rem);
  line-height: 1.25rem;
  line-height: var(--mdc-typography-body2-line-height, 1.25rem);
  font-weight: 400;
  font-weight: var(--mdc-typography-body2-font-weight, 400);
  letter-spacing: .0178571em;
  letter-spacing: var(--mdc-typography-body2-letter-spacing, .0178571em);
  text-decoration: inherit;
  text-decoration: var(--mdc-typography-body2-text-decoration, inherit);
  text-transform: inherit;
  text-transform: var(--mdc-typography-body2-text-transform, inherit);
}

.mdc-data-table {
  background-color: #fff;
  background-color: var(--mdc-theme-surface, #fff);
  border-radius: 4px;
  border-radius: var(--mdc-shape-medium, 4px);
  -webkit-overflow-scrolling: touch;
  box-sizing: border-box;
  border: 1px solid #0000001f;
  flex-direction: column;
  display: inline-flex;
  position: relative;
}

.mdc-data-table .mdc-data-table__header-cell:first-child {
  border-top-left-radius: 4px;
  border-top-left-radius: var(--mdc-shape-medium, 4px);
}

[dir="rtl"] .mdc-data-table .mdc-data-table__header-cell:first-child, .mdc-data-table .mdc-data-table__header-cell:first-child[dir="rtl"] {
  border-top-right-radius: 4px;
  border-top-right-radius: var(--mdc-shape-medium, 4px);
  border-top-left-radius: 0;
}

.mdc-data-table .mdc-data-table__header-cell:last-child {
  border-top-right-radius: 4px;
  border-top-right-radius: var(--mdc-shape-medium, 4px);
}

[dir="rtl"] .mdc-data-table .mdc-data-table__header-cell:last-child, .mdc-data-table .mdc-data-table__header-cell:last-child[dir="rtl"] {
  border-top-left-radius: 4px;
  border-top-left-radius: var(--mdc-shape-medium, 4px);
  border-top-right-radius: 0;
}

.mdc-data-table.mdc-data-table--without-footer .mdc-data-table__row:last-child .mdc-data-table__cell:first-child {
  border-bottom-left-radius: 4px;
  border-bottom-left-radius: var(--mdc-shape-medium, 4px);
}

[dir="rtl"] .mdc-data-table.mdc-data-table--without-footer .mdc-data-table__row:last-child .mdc-data-table__cell:first-child, .mdc-data-table.mdc-data-table--without-footer .mdc-data-table__row:last-child .mdc-data-table__cell:first-child[dir="rtl"] {
  border-bottom-right-radius: 4px;
  border-bottom-right-radius: var(--mdc-shape-medium, 4px);
  border-bottom-left-radius: 0;
}

.mdc-data-table.mdc-data-table--without-footer .mdc-data-table__row:last-child .mdc-data-table__cell:last-child {
  border-bottom-right-radius: 4px;
  border-bottom-right-radius: var(--mdc-shape-medium, 4px);
}

[dir="rtl"] .mdc-data-table.mdc-data-table--without-footer .mdc-data-table__row:last-child .mdc-data-table__cell:last-child, .mdc-data-table.mdc-data-table--without-footer .mdc-data-table__row:last-child .mdc-data-table__cell:last-child[dir="rtl"] {
  border-bottom-left-radius: 4px;
  border-bottom-left-radius: var(--mdc-shape-medium, 4px);
  border-bottom-right-radius: 0;
}

.mdc-data-table__row {
  background-color: inherit;
}

.mdc-data-table__header-cell {
  background-color: #fff;
  background-color: var(--mdc-theme-surface, #fff);
}

.mdc-data-table__row--selected {
  background-color: #6200ee0a;
}

.mdc-data-table__pagination-rows-per-page-select:not(.mdc-select--disabled) .mdc-notched-outline__leading, .mdc-data-table__pagination-rows-per-page-select:not(.mdc-select--disabled) .mdc-notched-outline__notch, .mdc-data-table__pagination-rows-per-page-select:not(.mdc-select--disabled) .mdc-notched-outline__trailing {
  border-color: #0000001f;
}

.mdc-data-table__cell, .mdc-data-table__header-cell {
  border-bottom-color: #0000001f;
}

.mdc-data-table__pagination {
  border-top-color: #0000001f;
}

.mdc-data-table__cell, .mdc-data-table__header-cell {
  border-bottom-style: solid;
  border-bottom-width: 1px;
}

.mdc-data-table__pagination {
  border-top-style: solid;
  border-top-width: 1px;
}

.mdc-data-table__row:last-child .mdc-data-table__cell {
  border-bottom: none;
}

.mdc-data-table__row:not(.mdc-data-table__row--selected):hover {
  background-color: #0000000a;
}

.mdc-data-table__header-cell, .mdc-data-table__pagination-total, .mdc-data-table__pagination-rows-per-page-label, .mdc-data-table__cell {
  color: #000000de;
}

.mdc-data-table__row {
  height: 52px;
}

.mdc-data-table__pagination {
  min-height: 52px;
}

.mdc-data-table__header-row {
  height: 56px;
}

.mdc-data-table__cell, .mdc-data-table__header-cell {
  padding: 0 16px;
}

.mdc-data-table__header-cell--checkbox, .mdc-data-table__cell--checkbox {
  padding-left: 4px;
  padding-right: 0;
}

[dir="rtl"] .mdc-data-table__header-cell--checkbox, [dir="rtl"] .mdc-data-table__cell--checkbox, .mdc-data-table__header-cell--checkbox[dir="rtl"], .mdc-data-table__cell--checkbox[dir="rtl"] {
  padding-left: 0;
  padding-right: 4px;
}

.mdc-data-table__table-container {
  -webkit-overflow-scrolling: touch;
  width: 100%;
  overflow-x: auto;
}

.mdc-data-table__table {
  min-width: 100%;
  white-space: nowrap;
  border-spacing: 0;
  table-layout: fixed;
  border: 0;
}

.mdc-data-table__cell {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Roboto, sans-serif;
  font-family: var(--mdc-typography-body2-font-family, var(--mdc-typography-font-family, Roboto, sans-serif));
  font-size: .875rem;
  font-size: var(--mdc-typography-body2-font-size, .875rem);
  line-height: 1.25rem;
  line-height: var(--mdc-typography-body2-line-height, 1.25rem);
  font-weight: 400;
  font-weight: var(--mdc-typography-body2-font-weight, 400);
  letter-spacing: .0178571em;
  letter-spacing: var(--mdc-typography-body2-letter-spacing, .0178571em);
  text-decoration: inherit;
  text-decoration: var(--mdc-typography-body2-text-decoration, inherit);
  text-transform: inherit;
  text-transform: var(--mdc-typography-body2-text-transform, inherit);
  box-sizing: border-box;
  text-align: left;
  text-overflow: ellipsis;
  overflow: hidden;
}

[dir="rtl"] .mdc-data-table__cell, .mdc-data-table__cell[dir="rtl"], .mdc-data-table__cell--numeric {
  text-align: right;
}

[dir="rtl"] .mdc-data-table__cell--numeric, .mdc-data-table__cell--numeric[dir="rtl"] {
  text-align: left;
}

.mdc-data-table__cell--checkbox {
  width: 1px;
}

.mdc-data-table__header-cell {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Roboto, sans-serif;
  font-family: var(--mdc-typography-subtitle2-font-family, var(--mdc-typography-font-family, Roboto, sans-serif));
  font-size: .875rem;
  font-size: var(--mdc-typography-subtitle2-font-size, .875rem);
  line-height: 1.375rem;
  line-height: var(--mdc-typography-subtitle2-line-height, 1.375rem);
  font-weight: 500;
  font-weight: var(--mdc-typography-subtitle2-font-weight, 500);
  letter-spacing: .00714286em;
  letter-spacing: var(--mdc-typography-subtitle2-letter-spacing, .00714286em);
  text-decoration: inherit;
  text-decoration: var(--mdc-typography-subtitle2-text-decoration, inherit);
  text-transform: inherit;
  text-transform: var(--mdc-typography-subtitle2-text-transform, inherit);
  box-sizing: border-box;
  text-overflow: ellipsis;
  text-align: left;
  outline: none;
  overflow: hidden;
}

[dir="rtl"] .mdc-data-table__header-cell, .mdc-data-table__header-cell[dir="rtl"] {
  text-align: right;
}

.mdc-data-table__header-cell--checkbox {
  width: 1px;
}

.mdc-data-table__header-cell--numeric {
  text-align: right;
}

[dir="rtl"] .mdc-data-table__header-cell--numeric, .mdc-data-table__header-cell--numeric[dir="rtl"] {
  text-align: left;
}

.mdc-data-table__sort-icon-button {
  width: 28px;
  height: 28px;
  opacity: 0;
  margin-left: 4px;
  margin-right: 0;
  padding: 2px;
  transition: transform .15s cubic-bezier(.4, 0, .2, 1);
  transform: rotate(.0001deg);
}

.mdc-data-table__sort-icon-button .mdc-icon-button__focus-ring {
  display: none;
}

.mdc-data-table__sort-icon-button.mdc-ripple-upgraded--background-focused .mdc-icon-button__focus-ring, .mdc-data-table__sort-icon-button:not(.mdc-ripple-upgraded):focus .mdc-icon-button__focus-ring {
  max-height: 28px;
  max-width: 28px;
  display: block;
}

@media screen and (forced-colors: active) {
  .mdc-data-table__sort-icon-button.mdc-ripple-upgraded--background-focused .mdc-icon-button__focus-ring, .mdc-data-table__sort-icon-button:not(.mdc-ripple-upgraded):focus .mdc-icon-button__focus-ring {
    pointer-events: none;
    box-sizing: content-box;
    height: 100%;
    width: 100%;
    border: 2px solid #0000;
    border-radius: 6px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

@media screen and (forced-colors: active) and (forced-colors: active) {
  .mdc-data-table__sort-icon-button.mdc-ripple-upgraded--background-focused .mdc-icon-button__focus-ring, .mdc-data-table__sort-icon-button:not(.mdc-ripple-upgraded):focus .mdc-icon-button__focus-ring {
    border-color: CanvasText;
  }
}

@media screen and (forced-colors: active) {
  .mdc-data-table__sort-icon-button.mdc-ripple-upgraded--background-focused .mdc-icon-button__focus-ring:after, .mdc-data-table__sort-icon-button:not(.mdc-ripple-upgraded):focus .mdc-icon-button__focus-ring:after {
    content: "";
    height: calc(100% + 4px);
    width: calc(100% + 4px);
    border: 2px solid #0000;
    border-radius: 8px;
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

@media screen and (forced-colors: active) and (forced-colors: active) {
  .mdc-data-table__sort-icon-button.mdc-ripple-upgraded--background-focused .mdc-icon-button__focus-ring:after, .mdc-data-table__sort-icon-button:not(.mdc-ripple-upgraded):focus .mdc-icon-button__focus-ring:after {
    border-color: CanvasText;
  }
}

.mdc-data-table__sort-icon-button.mdc-icon-button--reduced-size .mdc-icon-button__ripple {
  width: 28px;
  height: 28px;
  margin: 0;
}

.mdc-data-table__sort-icon-button.mdc-icon-button--reduced-size.mdc-ripple-upgraded--background-focused .mdc-icon-button__focus-ring, .mdc-data-table__sort-icon-button.mdc-icon-button--reduced-size:not(.mdc-ripple-upgraded):focus .mdc-icon-button__focus-ring {
  max-height: 28px;
  max-width: 28px;
}

.mdc-data-table__sort-icon-button .mdc-icon-button__touch {
  height: 28px;
  width: 28px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

[dir="rtl"] .mdc-data-table__sort-icon-button, .mdc-data-table__sort-icon-button[dir="rtl"], .mdc-data-table__header-cell--numeric .mdc-data-table__sort-icon-button {
  margin-left: 0;
  margin-right: 4px;
}

[dir="rtl"] .mdc-data-table__header-cell--numeric .mdc-data-table__sort-icon-button, .mdc-data-table__header-cell--numeric .mdc-data-table__sort-icon-button[dir="rtl"] {
  margin-left: 4px;
  margin-right: 0;
}

.mdc-data-table__header-cell--sorted-descending .mdc-data-table__sort-icon-button {
  transform: rotate(-180deg);
}

.mdc-data-table__sort-icon-button:focus, .mdc-data-table__header-cell:hover .mdc-data-table__sort-icon-button, .mdc-data-table__header-cell--sorted .mdc-data-table__sort-icon-button {
  opacity: 1;
}

.mdc-data-table__header-cell-wrapper {
  vertical-align: middle;
  align-items: center;
  display: inline-flex;
}

.mdc-data-table__header-cell--with-sort {
  cursor: pointer;
}

.mdc-data-table__sort-status-label {
  clip: rect(1px, 1px, 1px, 1px);
  height: 1px;
  white-space: nowrap;
  width: 1px;
  position: absolute;
  overflow: hidden;
}

.mdc-data-table--sticky-header .mdc-data-table__header-cell {
  z-index: 1;
  position: sticky;
  top: 0;
}

.mdc-data-table__sort-icon-button {
  color: #0009;
}

.mdc-data-table__sort-icon-button .mdc-icon-button__ripple:before, .mdc-data-table__sort-icon-button .mdc-icon-button__ripple:after {
  background-color: #0009;
  background-color: var(--mdc-ripple-color, #0009);
}

.mdc-data-table__sort-icon-button:hover .mdc-icon-button__ripple:before, .mdc-data-table__sort-icon-button.mdc-ripple-surface--hover .mdc-icon-button__ripple:before {
  opacity: .04;
  opacity: var(--mdc-ripple-hover-opacity, .04);
}

.mdc-data-table__sort-icon-button.mdc-ripple-upgraded--background-focused .mdc-icon-button__ripple:before, .mdc-data-table__sort-icon-button:not(.mdc-ripple-upgraded):focus .mdc-icon-button__ripple:before {
  opacity: .12;
  opacity: var(--mdc-ripple-focus-opacity, .12);
  transition-duration: 75ms;
}

.mdc-data-table__sort-icon-button:not(.mdc-ripple-upgraded) .mdc-icon-button__ripple:after {
  transition: opacity .15s linear;
}

.mdc-data-table__sort-icon-button:not(.mdc-ripple-upgraded):active .mdc-icon-button__ripple:after {
  opacity: .12;
  opacity: var(--mdc-ripple-press-opacity, .12);
  transition-duration: 75ms;
}

.mdc-data-table__sort-icon-button.mdc-ripple-upgraded {
  --mdc-ripple-fg-opacity: var(--mdc-ripple-press-opacity, .12);
}

.mdc-data-table__header-cell--sorted .mdc-data-table__sort-icon-button {
  color: #000000de;
}

.mdc-data-table__header-cell--sorted .mdc-data-table__sort-icon-button .mdc-icon-button__ripple:before, .mdc-data-table__header-cell--sorted .mdc-data-table__sort-icon-button .mdc-icon-button__ripple:after {
  background-color: #000000de;
  background-color: var(--mdc-ripple-color, #000000de);
}

.mdc-data-table__header-cell--sorted .mdc-data-table__sort-icon-button:hover .mdc-icon-button__ripple:before, .mdc-data-table__header-cell--sorted .mdc-data-table__sort-icon-button.mdc-ripple-surface--hover .mdc-icon-button__ripple:before {
  opacity: .04;
  opacity: var(--mdc-ripple-hover-opacity, .04);
}

.mdc-data-table__header-cell--sorted .mdc-data-table__sort-icon-button.mdc-ripple-upgraded--background-focused .mdc-icon-button__ripple:before, .mdc-data-table__header-cell--sorted .mdc-data-table__sort-icon-button:not(.mdc-ripple-upgraded):focus .mdc-icon-button__ripple:before {
  opacity: .12;
  opacity: var(--mdc-ripple-focus-opacity, .12);
  transition-duration: 75ms;
}

.mdc-data-table__header-cell--sorted .mdc-data-table__sort-icon-button:not(.mdc-ripple-upgraded) .mdc-icon-button__ripple:after {
  transition: opacity .15s linear;
}

.mdc-data-table__header-cell--sorted .mdc-data-table__sort-icon-button:not(.mdc-ripple-upgraded):active .mdc-icon-button__ripple:after {
  opacity: .12;
  opacity: var(--mdc-ripple-press-opacity, .12);
  transition-duration: 75ms;
}

.mdc-data-table__header-cell--sorted .mdc-data-table__sort-icon-button.mdc-ripple-upgraded {
  --mdc-ripple-fg-opacity: var(--mdc-ripple-press-opacity, .12);
}

.mdc-data-table__progress-indicator {
  width: 100%;
  display: none;
  position: absolute;
}

.mdc-data-table--in-progress .mdc-data-table__progress-indicator {
  display: block;
}

.mdc-data-table__scrim {
  background-color: #fff;
  background-color: var(--mdc-theme-surface, #fff);
  height: 100%;
  opacity: .32;
  width: 100%;
  position: absolute;
  top: 0;
}

.mdc-data-table__pagination {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Roboto, sans-serif;
  font-family: var(--mdc-typography-body2-font-family, var(--mdc-typography-font-family, Roboto, sans-serif));
  font-size: .875rem;
  font-size: var(--mdc-typography-body2-font-size, .875rem);
  line-height: 1.25rem;
  line-height: var(--mdc-typography-body2-line-height, 1.25rem);
  font-weight: 400;
  font-weight: var(--mdc-typography-body2-font-weight, 400);
  letter-spacing: .0178571em;
  letter-spacing: var(--mdc-typography-body2-letter-spacing, .0178571em);
  text-decoration: inherit;
  text-decoration: var(--mdc-typography-body2-text-decoration, inherit);
  text-transform: inherit;
  text-transform: var(--mdc-typography-body2-text-transform, inherit);
  box-sizing: border-box;
  justify-content: flex-end;
  display: flex;
}

.mdc-data-table__pagination-trailing {
  flex-wrap: wrap;
  justify-content: flex-end;
  align-items: center;
  margin-left: 4px;
  margin-right: 0;
  display: flex;
}

[dir="rtl"] .mdc-data-table__pagination-trailing, .mdc-data-table__pagination-trailing[dir="rtl"] {
  margin-left: 0;
  margin-right: 4px;
}

.mdc-data-table__pagination-navigation {
  align-items: center;
  display: flex;
}

.mdc-data-table__pagination-button {
  margin-left: 0;
  margin-right: 4px;
}

[dir="rtl"] .mdc-data-table__pagination-button .mdc-button__icon, .mdc-data-table__pagination-button .mdc-button__icon[dir="rtl"] {
  transform: rotate(180deg);
}

[dir="rtl"] .mdc-data-table__pagination-button, .mdc-data-table__pagination-button[dir="rtl"] {
  margin-left: 4px;
  margin-right: 0;
}

.mdc-data-table__pagination-total {
  white-space: nowrap;
  margin-left: 14px;
  margin-right: 36px;
}

[dir="rtl"] .mdc-data-table__pagination-total, .mdc-data-table__pagination-total[dir="rtl"] {
  margin-left: 36px;
  margin-right: 14px;
}

.mdc-data-table__pagination-rows-per-page {
  align-items: center;
  margin-left: 0;
  margin-right: 22px;
  display: inline-flex;
}

[dir="rtl"] .mdc-data-table__pagination-rows-per-page, .mdc-data-table__pagination-rows-per-page[dir="rtl"] {
  margin-left: 22px;
  margin-right: 0;
}

.mdc-data-table__pagination-rows-per-page-label {
  white-space: nowrap;
  margin-left: 0;
  margin-right: 12px;
}

[dir="rtl"] .mdc-data-table__pagination-rows-per-page-label, .mdc-data-table__pagination-rows-per-page-label[dir="rtl"] {
  margin-left: 12px;
  margin-right: 0;
}

.mdc-data-table__pagination-rows-per-page-select {
  min-width: 80px;
  min-width: var(--mdc-menu-min-width, 80px);
  margin: 8px 0;
}

.mdc-data-table__pagination-rows-per-page-select .mdc-select__anchor {
  width: 100%;
  min-width: 80px;
  height: 36px;
}

.mdc-data-table__pagination-rows-per-page-select .mdc-select__anchor .mdc-floating-label--float-above {
  font-size: .75rem;
  transform: translateY(-27.25px)scale(1);
}

.mdc-data-table__pagination-rows-per-page-select .mdc-select__anchor.mdc-notched-outline--upgraded .mdc-floating-label--float-above, .mdc-data-table__pagination-rows-per-page-select .mdc-select__anchor .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
  font-size: 1rem;
  transform: translateY(-24.75px)scale(.75);
}

.mdc-data-table__pagination-rows-per-page-select .mdc-select__anchor .mdc-floating-label--shake {
  animation: .25s mdc-floating-label-shake-float-above-select-outlined-36px;
}

@keyframes mdc-floating-label-shake-float-above-select-outlined-36px {
  0% {
    transform: translateX(0)translateY(-24.75px)scale(.75);
  }

  33% {
    animation-timing-function: cubic-bezier(.5, 0, .701732, .495819);
    transform: translateX(4%)translateY(-24.75px)scale(.75);
  }

  66% {
    animation-timing-function: cubic-bezier(.302435, .381352, .55, .956352);
    transform: translateX(-4%)translateY(-24.75px)scale(.75);
  }

  100% {
    transform: translateX(0)translateY(-24.75px)scale(.75);
  }
}

.mdc-data-table__pagination-rows-per-page-select .mdc-select__dropdown-icon {
  width: 20px;
  height: 20px;
}

.mdc-data-table__pagination-rows-per-page-select.mdc-select--outlined .mdc-select__anchor :not(.mdc-notched-outline--notched) .mdc-notched-outline__notch {
  max-width: calc(100% - 56px);
}

.mdc-data-table__pagination-rows-per-page-select .mdc-list-item.mdc-list-item--with-one-line {
  height: 36px;
}

@media screen and (max-width: 640px) {
  .d-md-block {
    display: block;
  }

  .d-md-none {
    display: none;
  }

  .subtitle {
    text-align: center;
    flex-direction: column;
  }

  .subtitle h5 {
    line-height: 1.5rem;
  }

  .mdc-data-table .mdc-data-table__cell {
    white-space: break-spaces;
    line-height: 1.4;
  }

  .mdc-data-table .mdc-data-table__cell.result {
    text-align: right;
  }

  .mdc-data-table .embedded-region {
    margin-top: .4rem;
    font-style: italic;
  }
}

@media (hover: none) {
  #how-it-works-dialog-content a {
    text-decoration: underline;
  }

  .mdc-data-table__sort-icon-button {
    opacity: .2;
  }
}

:root {
  --mdc-theme-primary: #1a73e8;
  --mdc-theme-secondary: #0000000a;
  --mdc-theme-success: #09af00;
  --mdc-typography-body2-font-size: 13px;
  --mdc-typography-body2-line-height: 1.5rem;
  --icon-margin-horizontal: 5px;
}

body, input {
  color: #485656;
  margin: 0;
  font-family: Roboto, sans-serif;
}

.d-block {
  display: block;
}

.d-flex {
  display: flex;
}

.d-none {
  display: none;
}

.flex-align-center {
  align-items: center;
}

.flex-justify-center {
  justify-content: center;
}

.mdc-top-app-bar__row {
  width: 90%;
  margin-left: auto;
  margin-right: auto;
}

.mdc-top-app-bar__row .mdc-top-app-bar__section {
  padding-left: 0;
  padding-right: 0;
}

.logo-container {
  align-items: center;
  padding: 0;
  display: inline-flex;
}

.logo-container .logo {
  margin-right: 10px;
}

.app-bar-link {
  align-items: center;
  margin: 0 10px;
  display: inline-flex;
}

.app-bar-link .material-icons {
  margin-right: var(--icon-margin-horizontal);
}

.subtitle {
  border-bottom: 1px solid #ccc;
  justify-content: space-between;
  align-items: center;
  padding: 0 5%;
  display: flex;
}

.subtitle h5 {
  margin: 1.5rem 0;
  font-size: 1.2rem;
  font-weight: 400;
}

#stopstart {
  --mdc-text-button-with-icon-icon-size: 1.5rem;
}

#stopstart > span {
  align-items: center;
  font-size: 1.2rem;
  font-weight: 400;
  display: inline-flex;
}

#stopstart > span.show-on-running {
  display: none;
}

#stopstart.running > span.show-on-running {
  display: inline-flex;
}

#stopstart.running > span.show-on-stopped {
  display: none;
}

.results-cell {
  text-align: center;
}

.mdc-data-table {
  width: 90%;
  margin: 20px auto;
}

.mdc-data-table .mdc-data-table__row, .mdc-data-table .mdc-data-table__header-row {
  height: auto;
}

.mdc-data-table .mdc-data-table__header-cell {
  background-color: var(--mdc-theme-secondary);
}

.mdc-data-table .mdc-data-table__header-cell, .mdc-data-table .mdc-data-table__cell {
  padding: 4px 16px;
}

.mdc-data-table tbody tr.fastest-region td:first-child {
  border-left: 4px solid var(--mdc-theme-success);
}

.sticky {
  z-index: 1;
  position: sticky;
  top: 0;
}

a, a:visited {
  color: inherit;
  text-decoration: none;
}

h2 {
  font-size: 22px;
  font-weight: 500;
  line-height: 14px;
}

p {
  margin-bottom: 4px;
}

#how-it-works-dialog-content a:hover {
  text-decoration: underline;
}

#how-it-works-dialog-content .material-icons {
  vertical-align: middle;
  display: inline-block;
}

/*# sourceMappingURL=index.77fb90ce.css.map */
